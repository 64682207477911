<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t(translationPath + 'title_assets') }}</h1>
      </b-row>
      <account-plan-submenu active="assets"></account-plan-submenu>

      <div class="wrapper_white_with_border py-4">

        <account-plan-assets-header></account-plan-assets-header>

        <b-card no-body class="white-tabs-card">
          <b-tabs card class="white-tabs"  @activate-tab="onTabActivate">
            <AssetsMenu>
              <template slot="account-plan-assets-match-globals">
                <b-row no-gutters>
                  <b-col cols="10">
                    <div class="text-regular" v-html="$t(translationPath + 'asset.global_match.description')"></div>
                  </b-col>
                </b-row>

                <b-table class="spirecta-simple-table account-plan-table income-accounts-table" show-empty hover responsive striped
                         stacked="md"
                         :items="tableRows"
                         :fields="fieldValues"
                         :tbodyTrClass="trClass"
                         :busy="busy"
                >
                  <template v-slot:table-busy>
                    <loader/>
                  </template>

                  <template v-slot:cell(title)="row">
                    <template v-if="row.item && row.item.is_account === true">
                      <span class="px-2 account_title">&ndash;</span>
                      <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'" class="account_title">{{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}</b-link>
                      <b-badge v-if="row.item.status === 'suspended'" variant="warning" class="ml-2 cursor-help" :title="$t(translationPath+'badge_suspended_help')">{{ $t( translationPath + 'badge_suspended') }}</b-badge>
                    </template>
                    <template v-else-if="row.item">
                      <b-link :to="'/reports/performance/account-groups/'+row.item.id" class="account_group_title">
                        {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                      </b-link>
                    </template>
                    <template v-else>&nbsp;</template>
                  </template>

                  <template v-slot:cell(global_id)="row">
                    <template v-if="row.item && row.item.is_account">
                      <custom-select
                        v-model="row.item.globalAccount"
                        :options="globalAccountsGrouped.asset"
                        grouping-values="accounts"
                        grouping-label="title"
                        label="title"
                        :placeholder="$t('common.select_account').toString()"
                        :search-placeholder="$t('common.type_to_filter').toString()"
                        @select="onChange"
                      ></custom-select>
                    </template>
                    <template v-else>
                      <div class="tr_heading">{{row.field.label}}</div>
                    </template>
                  </template>
                </b-table>

                <b-row class="mb-5 mt-4">
                  <b-col class="pt-4 pt-md-1">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="float-right ml-3 px-4 btn-save"
                      :disabled="saveDisabled || busy"
                      @click="onSave"
                    >{{ $t('common.save') }}</b-button>
                  </b-col>
                </b-row>
              </template>
            </AssetsMenu>
          </b-tabs>
        </b-card>

      </div><!-- END: wrapper_white_with_border -->
    </div><!-- END: main-content-wrapper -->
    <confirm-modal
      ref="ConfirmModal"
      :title="$t('common.please_confirm').toString()"
      :message="$t('reports.other.account_plan.confirm_page_leave_message').toString()"
      variant="primary"
      @confirmed="onLeavePageConfirmed"
    ></confirm-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import AccountPlanTabMixin from './AccountPlanTabMixin'
import CustomSelect from '@/components/common/CustomSelect'
import AssetsMenu from './submenu/AssetsMenu'
import AccountPlanAssetsHeader from './headers/AccountPlanAssetsHeader'

export default {
  name: 'AccountPlanAssetsMatchGlobals',
  components: { CustomSelect, AssetsMenu, AccountPlanAssetsHeader },
  mixins: [AccountPlanTabMixin],
  data () {
    return {
      accountsFilter: ''
    }
  },
  computed: {
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title') },
        { key: 'global_id', label: this.$t('incomes_expenses.accounts.create_account_wizard.common.step3.select_global_' + (this.currentCOA.strict_accounting_mode ? 'account' : 'category')) }
      ]
    },
    tableRows () {
      if (this.accountsGrouped.asset === null) {
        return []
      }

      const rows = []
      this.accountsGrouped.asset.map((item) => {
        if (!item.is_account) {
          rows.push(item)
        } else {
          item.globalAccount = null
          if (item.global_id && this.globalAccountsGrouped.asset !== null) {
            this.globalAccountsGrouped.asset.map((group) => {
              group.accounts.map((globalAccount) => {
                if (item.global_id === globalAccount.id) {
                  item.globalAccount = globalAccount
                }
              })
            })
          }
          rows.push(item)
        }
      })
      return rows
    }
  },
  methods: {
    async loadData () {
      if (!this.currentCOA.locale) {
        return true
      }

      this.busy = true
      Promise.all([
        this.loadAccountGroupsWithData('asset'),
        this.loadGlobalAccounts('asset')
      ])
        .then(() => {
          this.busy = false
        })
    },
    onSave () {
      this.saveDisabled = true

      const accounts = this.accountsGrouped.asset.filter((item) => item.is_account ? item : null)
      const putData = {
        accounts: []
      }
      accounts.map((item) => {
        putData.accounts.push({ id: item.id, global_id: item.globalAccount ? item.globalAccount.id : null })
      })

      axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/update-multiple`, putData)
        .then(() => {
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_globals_success_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_globals_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.hasUnsavedChanges = false
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_globals_fail_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_globals_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .then(() => {
          this.saveDisabled = false
        })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    }
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';
</style>
